<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">代付统计</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-date-picker v-model="date" size="small" type="daterange" unlink-panels
                      :clearable="false" class="el-date-picker family"
                      style="width: 240px !important;margin-right: 10px"
                      range-separator="/" format="yyyy-MM-dd" value-format="timestamp" :editable="false"/>
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectCount" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectCount">查询</el-button>
      <div style="width: 464px;display: flex;justify-content: flex-end;align-items: center">
        <el-button type="warning" @click="getBookAccount" size="small" icon="el-icon-notebook-2">
          书款账本
        </el-button>
      </div>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="counts" :row-style="rowStyle"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              style="width: 456px">
      <el-table-column label="供货商" prop="name" width="220" :resizable="false"/>
      <el-table-column label="代付单数" prop="count" width="75" :resizable="false"/>
      <el-table-column label="本数" prop="num" width="75" :resizable="false"/>
      <el-table-column label="代付书款" prop="pay" width="85" :resizable="false"
                       :formatter="priceFormat"/>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    created () {
      this.selectCount()
    },
    data () {
      return {
        query: {
          begin: '',
          end: '',
          name: '',
          page: 1
        },
        date: [Date.now(), Date.now()],
        counts: [],
        total: 0
      }
    },
    methods: {
      priceFormat (row) {
        return parseFloat(row.pay).toFixed(2)
      },
      rowStyle ({ rowIndex }) {
        let style = {}
        if (rowIndex === 0) {
          style = {
            height: '42px',
            color: '#409EFF'
          }
        } else {
          style = {
            height: '42px'
          }
        }
        return style
      },

      selectCount () {
        this.query.page = 1
        this.getCountList()
      },
      pageChange (page) {
        this.query.page = page
        this.getCountList()
      },
      async getCountList () {
        this.query.begin = this.date[0]
        this.query.end = this.date[1]
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getGetPayCountList',
            { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.counts = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.counts = res.data.data
          if (res.data.count.count !== 0) {
            this.counts.unshift(res.data.count)
          }
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.counts = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      getBookAccount () {
        const route = this.$router.resolve({ name: 'systembookaccount' })
        window.open(route.href, '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText {
    width: 14px !important;
    height: 14px !important;
    font-size: 14px;
    padding: 0;
    margin-bottom: 1px;
    margin-left: 6px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-range-separator {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
